<template>
  <div class="d-flex flex-column px-5 px-md-10 pt-8">
    <div class="d-flex flex-row align-center mb-8">
      <v-icon color="primary" size="16" v-text="'arrow_back'" />
      <a @click="$router.push({ name: 'calendar-schedule' })">
        <span class="ml-1 font-weight-bold">{{ $t('activity.editActivity.navigateBack') }}</span><span>{{ $t('project.sections.calendar') }}</span>
      </a>
    </div>
    <v-tabs v-model="tab" color="secondary">
      <v-tabs-slider color="primary" />
      <v-tab v-for="{ id, name, to } in tabs" :key="id" class="text-capitalize" :to="to" v-text="name" />
    </v-tabs>
    <v-divider />
    <router-view />
  </div>
</template>
<script>
import i18n from '@/plugins/i18n'

export default {
  name: 'EditGuildMatchActivity',
  data: () => ({ tab: null }),
  computed: {
    tabs: () => [
      { id: 'information', name: i18n.t('common.info'), to: { name: 'event-edit-guild-match' } },
      { id: 'tickets', name: i18n.t('organization.sections.tickets'), to: { name: 'tickets-edit-guild-match' } },
    ],
  },
}
</script>
